.services-container{
    background-color: black;
    padding: 15px;
    margin: 0px;
    display:block;
    margin-left: auto;
    margin-right:auto;
    overflow-x: hidden;
}

.services-container img{
    height: 55px;
}

.services-container h1{
    color: white;
    font-family: 'squareFont721';
    font-size: 55px;
}

.content-container-div{
    display: flex;
    justify-content:flex-end;
}

.content-container-div img{
    padding-right: 10px;
}