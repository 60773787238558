#carrouselContainer{
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: black;
    color: white;
    font-size: 35px;
    position: absolute;
    font-family: 'futuraLight';
    top: 0;
    left: 0;
}

#carrouselContainer a{
    color: #00aeef;
    text-decoration: none;
    font-weight: bolder;
    font-size: 3rem;
}

#carrouselContainer a:hover{
    color: white;
    letter-spacing: 2px;
}

#carrouselContainer span{

}

/* Carrousel & CarrouselCard components styling part */


.CarrouselCard{
    width: 100%;
    height: 70vh;
    background: #00aeef;
    font-size: 30px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: contrast(150%) sepia(50%);
}


/* FAIRE DES TEST DE FILTER POUR LES IMAGES DE LA GALERIE */
.CarrouselCard:hover{
    filter: brightness(120%) contrast(120%) sepia(0%);
}

.CarrouselCard img{
    height: 100%;
}

.rec.rec-arrow{
    color:#ffffff;
    background-color: #00aeef;
}

.rec.rec-arrow:hover{
    color: #00aeef;
    background-color: #ffffff;
}

.rec.rec-arrow:focus:enabled{
    background-color: black;
}

.rec.rec-arrow:hover:enabled{
    color: #00aeef;
    background-color: black;
}

.rec.rec-dot{
    background-color: white;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.2);
}

.rec.rec-dot:hover{
    background-color:#00aeef;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.2);
}

.rec.rec-dot:focus{
    background-color: #00aeef;
    box-shadow: 0 0 1px 3px;
}

.rec.rec-dot:focus:active{
    background-color: #00aeef;
    box-shadow: 0 0 1px 3px #00aeef;
}

.rec.rec-dot_active{
    background-color: #00aeef;
    box-shadow: 0 0 1px 3px white;
}



.empty-div-for-margin{
    margin-top: 3.5em;
}

@media (max-width: 480px) {
    .empty-div-for-margin{
        margin-top: 2.5em;
    }
}
