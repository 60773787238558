/* .top-margin-div{
    margin-top: 10em;
}

.video-row{
    margin-top: 2em;
    height: 200px;
}

.video-col{
    height: 200px;
}

.videoCard{
    width: 100%;
    background: #00aeef;
    color: white;
    font-size: 30px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: contrast(150%) sepia(50%);
} */



.videoContainer{
  /* height: 100vh;
  width: 100vw; */
  /* max-height: 800px;
  max-width: 1280px;
  min-height: 600px;
  min-width: 1000px; */
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  /* margin: 0 auto; */
  color:white;
  /* display: flex; */
  /* justify-content:space-evenly; */
  /* align-items: center; */
  width: auto;
  height: auto;
}


.card:hover{
    /* transform: scale(1.2); */
    /* transform:translate(80px,-30px);  pour faire monter en haut a droite */
    overflow: hidden;
    /* z-index: 10; */
}

.card .videoOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;/*100%*/
    height: 100%;
    background: transparent;
    transition: .5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg);
    transition: all .9s ease-in-out;
}

.card:hover .videoOverlay {
    transform: perspective(2000px) rotateY(0deg);
    border-radius: 20px;

    /* glassmorphism effect */
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    /* padding-left: 3em;
    padding-right: 3em; */
   
    backdrop-filter: blur(40px);
    border: solid 1px transparent;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px 10px rgba(36, 41, 49, 0.03);
    width: 100%;
}


.contour h3{
    padding:3px;
    transition: all .5s ease-in-out;
}

.card:hover .contour h3{
    color: transparent;
}

/* BORDER, To keep, or rework, or trash it? */
/* .border{
  height: 250px;
  width: 369px;
  background: transparent;
  border-radius: 10px;
  transition: border 1s;
  position: relative;
}

.border:hover{
    border: 1px solid white;
} */

/* .border h2{
    margin: 10%;
} */

.card{
  height: 260px;
  width: 30em;
  background: black;
  border-radius: 20px;
  border: 0px;
  transition:  0.8s;
  overflow: hidden;
  background: black;
  box-shadow: 0 70px 63px -60px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin:0;
  padding:0;
  cursor: pointer;
}

.card0{
    
    /* background: url('https://i.pinimg.com/736x/8f/a0/51/8fa051251f5ac2d0b756027089fbffde--terry-o-neill-al-pacino.jpg') center center no-repeat; */
    background: url(../../images/Realisations/HOLLYWOOD_SCREENSHOT.png) center center no-repeat;
    background-size: 150%;
}

.card0:hover{
    /* background: url('https://i.pinimg.com/736x/8f/a0/51/8fa051251f5ac2d0b756027089fbffde--terry-o-neill-al-pacino.jpg') left center no-repeat; */
    background: url(../../images/Realisations/HOLLYWOOD_SCREENSHOT.png) left center no-repeat;
    background-size: 200%;
}

.center h2{
    margin:10%;
}


.testnav{
    text-align: center;
    color: red;
    
}

.center{
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
}

.play_icon{
    text-align: center;
    color:white;
    font-size: 5em;
    margin-top: -50px;
}

.item{
    margin-top: 50px;
    /* width: 100px;
    height: 100px; */
    width: 45%;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(0,0,0,0.2) 0px 8px 24px;
}

.biggestContainer{
    height: 100vh;
    overflow: hidden;
}

.item_container{
    margin-top: 120px;
}

.mediumContainer button{
    margin-left: 50%;
}