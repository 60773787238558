* {
    /* padding: 0; */
    border: none;
    outline: none;
}

.contact-form {
    background-color: rgba(0, 0, 0, 0.3);
    max-width: 100%;
    padding: 0 30px 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.555);
    /* margin: 3rem auto 0; */
    /* max-width: 350px; */
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    /* border: 1px solid #18dcff; */
    /* background: #363434; */
}

.contact-form h2{
    margin-top: 35px;
    text-align: center;
}

.form-content{
    display: grid;
    grid-template-rows: repeat(4, 1fr) 105px;
}

input, textarea{
    background: none;
    font-family: 'squareFont721';
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    font-size: 1.5rem;
    border-bottom: 2px solid#00aeef;
    color: rgb(255, 255, 255);
}

input::placeholder, textarea::placeholder{
    color: rgb(255, 255, 255);
}

input, .email-content, textarea{
    width: 90%;
    margin: 0 auto;
}

input{
    padding: 24px 0 12px;
}

textarea{
    resize: none;
    padding: 24px 0;
}

.email-content{
    position: relative;
}

.email-content input{
    width: 100%;
}

#not-mail{
    display: none;
    position: absolute;
    top: 4px;
    color:#ff3333;
    right: 0;
    transform-origin: 50% 50%;
}

@keyframes dongle{
    0%{
        transform: translate(0px, 0px);
    }
    10%{
        transform: translate(-10px, 0px);
    }
    20%{
        transform: translate(10px, 0px);
    }
    30%{
        transform: translate(-10px, 0px);
    }
    40%{
        transform: translate(10px, 0px);
    }
    50%{
        transform: translate(-10px, 0px);
    }
    60%{
        transform: translate(10px, 0px);
    }
    70%{
        transform: translate(-10px, 0px);
    }
    80%{
        transform: translate(10px, 0px);
    }
    90%{
        transform: translate(-10px, 0px);
    }
    100%{
        transform: translate(0px, 0px);
    }
}

.button{
    font-size: 1.8rem;
    margin-top: 15px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: .2s;
    width: 100%;
}

.button:hover{
    letter-spacing: 2px;
    color: #00aeef;
}

.error::placeholder{
    color: #ff3333;
}

.form-message{
    margin-top: 10px;
    padding: 12px;
    opacity: 0;
    transition: 0.2s ease;
    color: black;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 50px rgba(0,0,0,1) inset !important;
}

input:-webkit-autofill{
    -webkit-text-fill-color: white !important;
}