.media-container{
    background-color: black;
    padding: 15px;
    margin: 0px;
}

.media-container img{
    height: 55px;
}

.media-container h1{
    color: white;
    font-family: 'squareFont721';
    font-size: 55px;
    float: left;
}

.media-container h5{
    padding-left: 10px;
    color: white;
    font-family: 'squareFont721Roman';
    font-size: 25px;
    float: left;
}

.media-left-div{
    float: left;
}

.media-mid-div{
    float: left;   
    padding-left: 10px;
}

.media-right-div{

}