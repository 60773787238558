.ContactContent-container-fluid{
    min-height: 100vh;
    font-family: 'futuraLight';
    padding-top: 2.5%;
    color: white;
    position: relative;
    z-index: 1;
}

.ContactContent-container-fluid::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .2;
    z-index: -1;
    background-image: url('../../images/contact_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;    
    background-attachment: fixed;
}


.ContactContent-container-fluid ul{
    font-family: 'squareFont721';
    list-style: none;
    font-size: 25px;
}

.ContactContent-container-fluid span{
    font-family: 'squareFont721';
    color: #00aeef;
    font-weight: bolder;
}

.ContactContent-container-fluid a{
    font-family: 'squareFont721';
    color: white;
    text-decoration: none;
}

.ContactContent-container-fluid a:hover{
    color: #00aeef;
}

.ContactContent-container-fluid h1{
    font-family: 'squareFont721';
    color: #00aeef;
}

.contact-left-div{
    float: left;
    padding-right: 20px;
}

.contact-right-div{
    padding-top: 50px;
}

.contact-form-div{

}