.MediaContent-container{
    font-family: 'squareFont721';
}


.MediaContent-container a:hover{
    color: #00aeef;
}

.MediaContent-container img{
    height: 40px;
}

.mediacontent-div{
    display: flex;
    justify-content: center;
    align-items:center;
    padding-top: 30%;
}

.mediacontent-title-div{
    float: left;
    padding-right: 20px;
}

.MediaContent-container h2{
    color: white;
}

.MediaContent-container span{
    color: #00aeef;
    font-weight: bolder;
}

.left-col, .mid-col, .right-col{
    position: relative;
    height: 360px;
    z-index: 1;
 }
 
.left-col::before{
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     opacity: .4;
     z-index: -1;
     background-image: url('../../images/media_left.jpg');
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
 }

 .mid-col::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .4;
    z-index: -1;
    background-image: url('../../images/media_mid.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.right-col::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .4;
    z-index: -1;
    background-image: url('../../images/media_right.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.closeAudioPlayer{
    padding-top: 1%;
    padding-bottom: 4%;
}

.closeAudioPlayer a{
    font-size: 45px;
    color: #00aeef;
    font-weight: bolder;
}

.closeAudioPlayer a:hover{
    text-decoration: none;
    color: white;
    letter-spacing: 2px;
}