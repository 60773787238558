.header-video-container{
    padding: 0;
    position: relative;
    overflow: hidden;
    background-color: black;
    height: 700px;
}

.header-video-container video{
    width: 100%;
    /* height: 700px; */
    /* object-fit: cover; */
    height: 100%;
    object-fit: cover;
}

.inner-div{
    display: inline-block;
    position: relative;
}

.overlay-div{
    position: absolute;
    left: 10%;
    right: 10%;
    top: 10%;
    z-index: 1;
}