.StudioContent-container-fluid{
   position: relative;
   z-index: 1;
}

.StudioContent-container-fluid p{
    font-family: 'futuraLight';
    font-size: 15px;
}

.StudioContent-container-fluid::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    z-index: -1;
    background-image: url('../../images/studio_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

/* Desactivation du fake parallax effect sur les appareils mobile */
@media only screen and (max-device-width: 1366px){
    .StudioContent-container-fluid::before{
        background-attachment: scroll;
    }
}

.StudioContent-container{
    color: white;
    font-family: 'squareFont721';
    text-align: justify;
}

.StudioContent-container span{
    color: #00aeef;
    font-weight: bolder;
}

.left{
    float: left;
    padding-top: 100px;
    height: 100%;
    padding-right: 15px;
}

.right{
    padding-top: 60px;
    padding-bottom: 80px;
}

.linkedin{
    font-family: 'futuraLight';
    text-decoration: none;
    color: white;
    font-size: 18px;
}

.linkedin:hover{
    color: #00aeef;
    text-decoration: none;
    letter-spacing: 0.2px;
}

.fondateur{
    width: 100%;
}