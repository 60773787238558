@font-face{
  font-family: 'squareFont721';
  src: url('fonts/square_721_condensed_bt.ttf')  format('truetype');
}

@font-face{
  font-family: 'futuraLight';
  src: url('fonts/futura_light_bt.ttf')  format('truetype');
}

@font-face{
  font-family: 'squareFont721Roman';
  src: url('fonts/Square721-BT-Roman.ttf') format('truetype');
}

html, body {
  background-color: black;
  width: auto!important; 
  overflow-x: hidden!important;
}
