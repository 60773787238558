#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  transition: color 0.2s;
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-family: 'squareFont721';
  padding-top: 1rem;
}

.bm-item span{
    color:#00aeef;
    font-size: 1.9rem;
    font-weight: bolder;
}
/* Change color on hover */
.bm-item:hover {
  color: #00aeef;
  text-decoration: none;
  letter-spacing: 2px;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.language-button{
  background: none;
  color: white;
  font-size: 0.7em;
}

.language-button:hover{
  color: #00aeef;
  letter-spacing: 2px;
}

#div-language{
  text-align: center;
}

#div-language span{
  font-size: 1em;
}
