/* *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
} */

#audioplayer-top-adjustment-div{
    margin-top: 20%;
}

#audioPlayerContainer{
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    font-family: 'futuraLight';
    top: 0;
    left: 0;
    /* z-index: -999999; */
    background: url('../../images/bluredAudioPlayerBG.png') no-repeat center center/cover;
    overflow: hidden;
    background-color: black;
}

/* Playlist */
.songLink{
    color: white;
    text-decoration: none;
    font-weight: normal;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.songLink:hover{
    color: #00aeef;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
}

/*playlistarrows*/

.playlist-go-top{
    font-size: 3rem;
    padding: 0.8rem;
}

.playlist-go-top:hover{
    cursor: pointer;
}

.playlist-go-down{
    font-size: 3rem;
    padding: 0.8rem;
    color: #00aeef;
}

.playlist-go-down:hover{
    cursor: pointer;
}

/* Audio Player.........................................................*/

.audio-controller-action{
    color: white;
    font-size: 1.8rem;
    padding: 20px;
}

.audio-controller-action:hover{
    color: #00aeef;
}

/* Progress bar */
#audio-progression{
    width: 80%;
    height: 5px;
    background-color: white;
    margin:auto;
    z-index: 99;
}

#audio-progression-value{
    width: 0%;
    height: 5px;
    background-color: #00aeef;
    z-index: 99;
}

/*Knob additional styling*/
.knobAdditionalStyling svg{
    position: relative;
    top:-20px;
    z-index: 1;
}

.knobAdditionalStyling text{
    font-size: 1.5em;
}
.knobAdditionalStyling svg{
    width: 8em;
    /*background-color: red;*/
}

.audioplayer-volume-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 2%;
    padding-top: 0;
    margin: 0;
    margin-top: -4em;
}

/*----------------------------------------------*/
/* Container glassmorphism effet */
.audio-player-controller-container{
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    /* padding-left: 3em;
    padding-right: 3em; */
    border-radius: 2em;
    backdrop-filter: blur(40px);
    border: solid 1px transparent;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px 10px rgba(36, 41, 49, 0.03);
    width: 100%;
    height: 100%;
}

.playlist-container-glassmorphism{
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    /* padding-left: 3em;
    padding-right: 3em; */
    border-radius: 2em;
    backdrop-filter: blur(40px);
    border: solid 1px transparent;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px 10px rgba(36, 41, 49, 0.03);
    width: 100%;
}

.control-mouse-hover{
    cursor: pointer;
}

/*----------------------------------------------*/

.musique-presentation-div{
    color: white;
    text-align: justify;
    padding: 2em;
}

@media (max-width: 480px) {
    .musique-presentation-div p{
        display: none;
    }
    .playlist-col{
        padding-bottom: 0.5em;
    }
    #audioplayer-top-adjustment-div{
        margin-top: 0%;
    }

    /*Knob Volume Controller*/
    .audioplayer-volume-container{
        display: none;
    }

    .spanSongTimeProgress{
        display: none;
    }
    .spanSongTimeDuration{
        display: none;
    }
}
