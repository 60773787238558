
.div_parallax_container{
    display: flex;
    justifyContent: center;
    alignItems: center;
    textAlign: center;
    width: 90%;
    margin: auto;
    padding-top: 15%;
}

.div_player{
    width: 50%;
    padding-left: 1em;
    padding-right: 1em;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

.div_text_content{
    padding-left: 1em;
    padding-right: 1em;
    width: 50%;
}

.h1_title{
    font-size: 5em;
    font-family: 'squareFont721';
}

.p_description{
    font-size: 1.5em;
    font-family: 'futuraLight';
}

@media (max-width: 756px) {
    h1{
        /* line-height: 0.8em;
        padding-left: 1em; */
        
    }

    .h1_title{
        font-size: 5em;
        font-family: 'squareFont721';
        padding-left: 1em;
        line-height: 0.8;
    }

    .div_parallax_container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        /* margin-top: 5em; */
        padding: 0;
        
        z-index: 99;
    }

    .p_description{
        z-index: 9;
        line-height: 1em;
    }

    .div_player{
        width: 100%;
        padding-top: 5em;
        padding-left: 1em;
        padding-right: 1em;
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*z-index: 99;*/
    }

    .div_text_content {
        padding-top: 2em;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
        height: 50%;
    }
}
