.PartenairesContent-container-fluid{
    position: relative;
    z-index: 1;
}

.PartenairesContent-container-fluid::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .4;
    z-index: -1;
    background-image: url('../../images/partenaires_background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.partenaires-details-row{
    padding-top: 25px;
    font-family: 'futuraLight';
    color: white;
    font-size: 20px;
}

.PartenairesContent-container span{
    font-weight: bolder;
}

.partenaires-logo-row{
    height: auto;
    padding-bottom: 100px;
}

.partenaires-col{
    display: flex;
    align-items: center;
}

.partenaires-logo-row img{
    padding-top: 20px;
    padding-bottom: 20px;
}