.video-overlay-container{
    background-color: rgba(0,0,0,0.2) ;
    height: 500px;
    padding-top: 10px;
}

.video-overlay-container ul{
    text-align: left;
    list-style-type: none;
    
}

.video-overlay-container a{
    color: white;
    font-family: 'squareFont721';
    font-size: 25px;
    text-decoration: none;
}

.video-overlay-container a:hover{
    color: #00aeef;
}

.video-overlay-container span{
    color: #00aeef;
    font-size: 25px;
}

.nav-col{
    margin-top: 225px;
    float: left;
}

.nav-col img{
    width: 10px;
    height: 80%;
}

.nav-div-left{
    float: left;
    margin-right: 50px;
}

.nav-div-right{
    float: left;
    /* padding-top: 10px; */
}

.logo-col{
    padding-top: 70px;
    /* width: auto;
    height: auto; */

}

.logo-col img{
    height: 350px;
}

.txt-col{
    text-align: justify;
    margin-top: 225px;
    color: white;
    font-size: 25px;
    font-family: 'squareFont721';
}

.button-div{
    position: relative;
    float: right;
    padding-top: 35px;
} 