/*html, body{*/
/*    overflow: scroll;*/
/*    overflow: hidden;*/
/*}*/

.testHide ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.background_digits_white{
    color: white;
    font-size: 35em;
    opacity: 0.1;
}

.background_digits_black{
    color: black;
    font-size: 35em;
    opacity: 0.1;
    text-align: right;
    z-index: 99;
}

.layer_digits_black{
    /*z-index: -99;*/
    float: right;
    text-align: right;
}


.parallaxLayerReverse{
    z-index: 99;
}

.div_parallax_not_reverse{
    z-index: 99;
}

#nav_div{
    position: fixed;
    bottom: 10px;
    width: 100%;
    color:#00aeef;
    cursor: pointer;

    z-index: 9999;
    text-align: center;
}

#nav_div a{
    padding-left: .5em;
    padding-right: .5em;
    font-size: 4em;
}

#nav_div a:hover{
    font-size: 6em;
}


@media (max-width: 756px) {

    #nav_div{
        position: fixed;
        bottom: 10px;
        left: 5%;
    }

    #nav_div b {
        text-align: center;
        font-size: 0.55em;
        /*visibility: hidden;*/
        /*z-index: -999;*/
    }

    .background_digits_white{
        color: white;
        font-size: 35em;
        opacity: 0.1;
        z-index: -1;
        /*visibility: hidden;*/
    }
}