.container-bg{
    position: relative;
    z-index: 1;
}

.container-bg::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .4;
    z-index: -1;
    background-image: url('../../images/services_background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow-x: hidden;
}

/* Desactivation du fake parallax effect sur les appareils mobile */
@media only screen and (max-device-width: 1366px){
    .container-bg::before{
        background-attachment: scroll;
    }
}

.service-row-top_{
}

.service-row-bottom_{
}


.testCol{
    padding:0;
    margin:0;
}

.backgroundImg{
    width:100%;
    height:250px;
}

.frame{
    padding:0;
    margin:0;
    text-align: center; 
    position: relative;
    cursor: pointer;  
    perspective: 500px; 
    color: white;
}

.frame h1{
    font-size: 20px;
    font-family: 'squareFont721';
}

.frame p{
    font-family: 'futuraLight';
    font-size: 14px;
    font-weight: lighter;
    text-align: justify;
}

.frame .details {
    width: 90%;
    height: 95%;  
    padding: 5% 8%;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateY(90deg);
    transform-origin: 50%;
    background: rgba(0,0,0,0.8);  
    opacity: 0;
    transition: all 0.4s ease-in;
}

.frame:hover .details{
    transform: translate(-50%, -50%) rotateY(0deg);
    opacity: 1;
}

.frame:hover .frame-title{
    opacity: 0;
}

.frame-title{
    position: absolute;
    top: 10px;
    right: 15px;
}

.frame-title h1{
    font-family: 'squareFont721';
    font-size: 23px;
}

.frame-title span{
    color: #00aeef;
    font-size: 25px;
    font-weight: bolder;
}

.runesImg{
    height: 17%;
    padding-bottom: 10px;
}